.test:focus {
    border: none;
}

.main-login-layout {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    flex-direction: row;
}

.main-card {
    width: 450px;
    margin-bottom: 30px;
    height: fit-content;
}

.left {
    width: 55%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.left::-webkit-scrollbar {
    display: none;
}

.left-side {
    height: 1000px;
}

.right {
    width: 45%;
    justify-content: flex-end;
}

.brand-slogan {
    display: none;
    text-align: center;
    margin-bottom: 40px;
    color: white;
}

.slogan {
    font-size: 28px;
}

.title-brand {
    font-size: 28px;
    margin-top: 30px;
}

.desp-brand {
    font-size: 14px;
    margin-top: 8px;
    margin-left: 2px;
}

@media only screen and (max-width: 1150px) {
    .right {
        width: 0;
    }

    .left {
        width: 100%;
    }

    .img-bg {
        width: 0;
        height: 0;
    }

    .brand-slogan {
        display: block;
    }

    .main-login-layout .copyrights {
        color: white
    }

    .main-login-layout .mcHeading {
        color: white
    }

    .title-brand {
        color: white;
    }

    .desp-brand {
        color: white;
    }

    .main-login-layout {
        background-image: url("../statics/loginMobileView_orange.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

@media only screen and (max-width: 450px) {
    .main-card {
        width: 350px;
        min-height: 200px;
    }


    .desp-brand {
        color: white;
        font-size: 12px;
        margin-top: 2px;
    }

    .slogan {
        font-size: 22px;
    }

    .slogan-2 {
        font-size: 14px;
    }
}