@import url("../../style/root.css");

.mc-text {
    color: black;
    font-weight: normal;
}

.mc-text-xx-small {
    font-size: .685rem;
}

.mc-text-small {
    font-size: .785rem;
}

.mc-text-x-small {
    font-size: .875rem;
    color: var(--secondary-color);
}

.mc-text-medium {
    font-size: .875rem
}

.mc-text-large {
    font-size: 1rem;
}

.mc-text-x-large {
    font-size: 1.255rem;
}

.mc-text-xx-large {
    font-size: 1.555rem;
}

.mc-text-bold {
    font-weight: 500;
}

.mc-text-bolder {
    font-weight: 700;
}

.mc-text-link-icon {
    padding: 3px;
}

.mc-text-link {
    color: var(--primary-color);
    cursor: pointer;
}

.mc-text-link:hover {
    text-decoration: underline;
}