:root {
    --primary-color: #d88027;
    --secondary-color: #3d3e3d;
    --bg-color: #f0f2f5;
    --btn-color: white;
    --alert-red: #fff5f5;
    --alert-green: #f5fff6;
    --alert-yellow: #fffbf5;
}

body {
    background-color: var(--bg-color);
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
}

[class*="ant-btn-primary"] {
    background-color: var(--primary-color) !important;
    color: var(--btn-color) !important;
}

[class*="ant-btn-default"]:hover:not([class*="ant-btn-dangerous"]) {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
}

[class*="ant-radio-wrapper"]:hover [class*="ant-radio"],
[class*="ant-radio"]:hover [class*="ant-radio-inner"],
[class*="ant-radio-input"]:focus+[class*="ant-radio-inner"] {
    border-color: var(--primary-color) !important;
}

[class*="ant-radio-checked"] [class*="ant-radio-inner"] {
    border-color: var(--primary-color) !important;
}

[class*="ant-radio-wrapper"] [class*="ant-radio-checked"] [class*="ant-radio-inner"] {
    background-color: var(--primary-color) !important;
}

[class*="ant-switch-checked"] {
    background-color: var(--primary-color) !important;
}

[class*="ant-checkbox"]:not([class*="ant-checkbox-disabled"]) {
    border-color: var(--primary-color) !important;
}

[class*="ant-checkbox-disabled"] [class="ant-checkbox-inner"] {
    border-color: #00000040 !important;
}

[class*="ant-checkbox-checked"] [class="ant-checkbox-inner"] {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
}

[class*="ant-checkbox-input"]:hover,
[class*="ant-checkbox-inner"]:hover,
[class*="ant-checkbox-input"]:focus+[class="ant-checkbox-inner"],
[class*="ant-checkbox-checked"]::after {
    border-color: var(--primary-color) !important;
}


[class*="ant-input-affix-wrapper"]:hover {
    border-color: var(--primary-color) !important;
}

[class*="ant-input-affix-wrapper-focused"] {
    border-color: var(--primary-color) !important;
}

[class*="ant-input"]:focus {
    border-color: var(--primary-color) !important;
}

[class*="ant-input"]:hover {
    border-color: var(--primary-color) !important;
}

[class*="ant-input-affix-wrapper-focused"] [class*="ant-input-wrapper"] {
    border-color: var(--primary-color) !important;
}

[class*="ant-select-open"]:hover {
    border-color: var(--primary-color) !important;
}

[class*="ant-select-selector"]:hover {
    border-color: var(--primary-color) !important;
}

[class*=".ant-select-focused"] [class*="ant-select"]:not([class*="ant-select-disabled"]):not([class*="ant-select-customize-input"]) [class*="ant-select-selector "] {
    box-shadow: none !important;
    border-color: transparent !important;
}

[class*="ant-select-focused"]:not([class*="ant-select-customize-input"]) [class*="ant-select-selector"] {
    border-color: var(--primary-color) !important;
}

[class*="ant-select-item-option-selected"] {
    background-color: rgba(230, 230, 232, 1) !important;
}

[class*="ant-pagination"] [class*="ant-pagination-item-active"] {
    border-color: var(--primary-color) !important;
}

[class*="ant-pagination"] a {
    color: var(--primary-color) !important;
}


[class*="ant-steps-item-process"]>[class*="ant-steps-item-container"]>[class*="ant-steps-item-icon"] {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

[class*="ant-steps-item-finish"]>[class*="ant-steps-item-container"]>[class*="ant-steps-item-content"]>[class*="ant-steps-item-title"]::after {
    background-color: var(--primary-color) !important;
}

[class*="ant-steps-item"] [class="ant-steps-item-tail"] {
    transition: background 0.15s ease-in-out;
}

[class*="ant-steps-item-finish"] [class*="ant-steps-item-icon"] {
    background-color: var(--btn-color) !important;
}

[class*="ant-steps-finish-icon"] {
    color: var(--primary-color) !important;
}
[class*="ant-spin-dot-item"] {
    background-color: var(--primary-color) !important;
}

[class*="ant-alert-error"] [class*="ant-alert-icon"] {
    color: #de2e37;
}

[class*="ant-tag"] {
    font-weight: 700;
}