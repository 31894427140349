@import url(./style/root.css);

* {
  margin: 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.epos-chat {
  background: transparent;
  border: none;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  padding-left: 5px;
  padding-bottom: 5px;
}

.reset-icon {
  margin-left: 14px;
  font-size: 20px;
  cursor: pointer;
}

.datepicker {
  height: 35px;
  width: 200px;
  font-size: 15px;
  padding-left: 13px;
}

.btn-with-icon {
  display: flex;
  align-items: center;
}

.mc-btn-icon, .mc-btn-icon-right {
  margin-right: 8px;
  font-size: 18px;
}

.mc-btn-icon-right {
  margin-left: 8px;
}

[class*="ant-btn"] {
  margin: 0 5px;
}

.savebar [class*="ant-card-body"] {
  padding: 12px 20px !important;
}

.main-content {
  padding: 16px;
  margin-top: 50px;
}

.sticky-location-name {
  position: fixed;
  top: 130px;
  z-index: 2;
  background-color: var(--bg-color);
  width: 100%;
}

[class*="ant-divider"] {
  border-block-start: 1px solid rgba(5, 5, 5, 0.18);
}

[class*="ant-divider-horizontal"] {
  margin: 20px 0;
}