@import url("../fields/field.css");

.info-dash [class*="ant-card-body"] {
    padding-top: 0 !important;
}

.logo-section {
    position: absolute;
    top: -40px;
}

.acc-sw-logo {
    width: 100px !important;
    height: 100px !important;
}

.info-dash-companyName {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-btn-section {
    text-align: end;
}

.info-dash-btns {
    display: flex;
    flex-direction: row;
}

.info-main-center {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filepath .info-main-center {
    margin-top: 0;
}

.info-dash-inputs, .info-dash-status {
    width: 48%;
}

.info-dash-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.info-sync-links {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}

.syncBadge {
    min-width: 30px;
    height: 18px;
    border-radius: 35px;
    margin-right: 6px;
    margin-top: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
}