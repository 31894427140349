.mc-th, .mc-tc {
    min-width: 140px;
    width: auto;
    height: 50px;
    background-color: #00000007;
    text-overflow: ellipsis;
    text-align: center;
}

.mc-tc {
    background-color: transparent;
}

.mc-th:nth-child(1) {
    border-top-left-radius: 15px;
}

.mc-th:last-child {
    border-top-right-radius: 15px;
}

.mc-tbody {
    width: 100%;
    background-color: white;
    border-color: 1px solid white;
}


.mc-tr-odd {
    background-color: #00000007;
}

.mc-tr-even {
    background-color: white;
}