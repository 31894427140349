@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body, p, h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  font-family: -apple-system, Lato, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, #root, html {
  height: 100%;
}

p {
  margin: 0;
  padding: 0;
}

[class*="fc-widget-normal"] {
  bottom: 55px !important;
}

.d-hotline {
  background-color: var(--primary-color) !important;
  border-color: transparent var(--primary-color) transparent transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}